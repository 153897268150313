<template>
  <v-row no-gutters justify="center">
    <v-col xs="12" sm="10" md="8">
      <panels-list/>
    </v-col>
    <v-col xs="12" sm="10" md="8">
      <buttons-dialog/>
    </v-col>
    <v-col xs="12" sm="10" md="8"> <!-- идеальное поведение-->
      <form-inputs/>
    </v-col>
    <v-col xs="12" sm="10" md="8">
      <table-data/>
    </v-col>
    <v-col xs="12" sm="10" md="4">
      <list-options/>
    </v-col>
  </v-row>
</template>

<script>

export default {
  components: {
    "form-inputs": () => import("@/site/modules/test/examples/form-inputs"),
    "buttons-dialog": () => import("@/site/modules/test/examples/buttons-dialog"),
    "table-data": () => import("@/site/modules/test/examples/table-data"),
    "list-options": () => import("@/site/modules/test/examples/list-options"),
    "panels-list": () => import("@/site/modules/test/examples/panels-list.vue"),
  }
}
</script>

<style scoped>

</style>